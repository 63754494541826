
// 
import { Colors, ColorClassBg, ColorClassFg } from './const';
import { alphabet } from './const';


export class MiscUtil {

  constructor() { }

  // -------------------------------------
  // static
  // -------------------------------------

  // http://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
  static shuffle(array:any[]) {
    var currentIndex = array.length
      , temporaryValue
      , randomIndex
      ;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  static listToGrid(list:any[], cols:number):Array<Array<any>> {
    let grid = Array(Math.ceil(list.length / cols));
    
    let rowNum = 0;
  
    for (let i = 0; i < list.length; i+=cols) {
  
      grid[rowNum] = Array(cols);
  
      for (var c = 0; c < cols; c++) {
        if (list[i+c])  grid[rowNum][c] = list[i+c];
      }
      
      rowNum++;
    }
    
    return grid;
  }

  static binarySearch (o:any[], v:any, i:boolean = false) { // array, object, insert:false
    if (!o) return -1;
    var h = o.length, l = -1, m;
    while(h - l > 1)
      if(o[m = h + l >> 1] < v) l = m;
      else h = m;
    return o[h] != v ? i ? h : -1 : h;
  }


  static clone(src:any):any {
    return JSON.parse(JSON.stringify(src));
  }

  static extend(target: any, source: any): any {
    Object.keys(source).forEach(attribut => {
      target[attribut] = source[attribut];
    });
    return target;
  }
  static copyKeysObj(source: any, target:any): any {
    Object.keys(target).forEach(attribut => {
      target[attribut] = source[attribut];
    });
    return target;
  }
  static copyKeys(source: any, keys:string[]): any {
    let target:any = { };
    keys.forEach(attribut => {
      target[attribut] = source[attribut];
    });
    return target;
  }
  
  

  static extendParam(source: any, target: any) {
    Object.keys(target).forEach(function (key) {
      source = source.append(key, target[key]);
    });
    return source;
  }

  static values(obj:any): any[] {
    var vals = [];
    for (var key in obj) {
        vals.push(obj[key]);
    }
    return vals;
  }

  static getUrlVars(hrefInput?:string):any {
    var vars:any = {}, hash;
    var href = hrefInput !== undefined ? hrefInput : window.location.href;
    if (href.indexOf('#') != -1) href = href.slice(0, href.indexOf('#'));
    var hashes = href.slice(href.indexOf('?') + 1).split('&');
    for ( var i = 0; i < hashes.length; i++) {
      hash = hashes[i].split('=');
      vars[hash[0]] = hash[1];
    }
    return vars;
  }

  static generatePassord(length:number) {
    var pw = "";
    while (pw.length < length) {
      var rnd = Math.floor(Math.random() * alphabet.length);
      pw += alphabet[rnd];
    }
    return pw;
  }

  
  static getDateStringAsInt(time:String):number {
    return parseInt(time.replace(/-/g, ""));
  }
  static getDateAsInt(time:Date = new Date()):number {
    // console.log("time: " + time + ", typeof: " + typeof time);
    // if (typeof time == "string") time = new Date(time);
    var yyyy  = time.getFullYear();
    var month = time.getMonth() + 1; // getMonth() is zero-based
    var mm    = month < 10              ? "0" + month             : month;
    var dd    = time.getDate() < 10     ? "0" + time.getDate()    : time.getDate();
    var dateString = "".concat(yyyy + "").concat(mm + "").concat(dd + "");
    // console.log("dateString: " + dateString);
    return parseInt(dateString);

    // return time.getFullYear()*10000 + (time.getMonth()+1)*100 + time.getDate();
  }
  static getDateAsString(date:Date = new Date()):string {
    let str = this.getDateAsInt(date) + "";

    return str.substring(0,4) 
      + "-" + str.substring(4,6)
      + "-" + str.substring(6,8)
  }

  static setDateAsInt(date:number):Date | null {
    if (!date) return null;
    var str = date.toString();
    if(!/^(\d){8}$/.test(str)) {
      console.log("invalid date: ", date);
      return null;
    }
    let y = parseInt(str.substring(0,4)),
        m = parseInt(str.substring(4,6)) - 1,
        d = parseInt(str.substring(6,8));
    return new Date(y,m,d);
  }


  static truncate(date:Date = new Date(), field: "date" | "hour" = "date"):Date {

    date.setMilliseconds(0);
    date.setSeconds(0);
    date.setMinutes(0);
    if (field == "hour") return date;

    date.setHours(0);

    return date;
  }

  static getTimeString(time: Date = new Date(), millis:boolean = false) {
    if (typeof time == "string") time = new Date(time);
    var yyyy = time.getFullYear();
    var month = time.getMonth() + 1; // getMonth() is zero-based
    var mm = month < 10 ? "0" + month : month;
    var dd = time.getDate() < 10 ? "0" + time.getDate() : time.getDate();
    var hh = time.getHours() < 10 ? "0" + time.getHours() : time.getHours();
    var min = time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
    var ss = time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds();
    var ms = time.getMilliseconds();
    var msString = (ms < 100 ? ("0" + (ms < 10 ? "0" : "")) : "") + ms
    return ""
            .concat(yyyy  + "")
            .concat(mm    + "")
            .concat(dd    + "")
            .concat(""    + "-")
            .concat(hh    + "")
            .concat(min   + "")
            .concat(ss    + "")
            .concat(millis ? "." + msString : "")
    ;
  }

  static isObjectEmpty(obj:any) {
    return (Object.keys(obj).length === 0);
  }

  static getDurationString(time:number) { //TODO: kan kanskje bruke moment?

    var secs = Math.round(time / 1000);
    var mins = 0;
    var hours = 0;

    if (secs > 60) {
      mins = Math.floor(secs / 60);
      secs = secs % 60;
    }
    if (mins > 60) {
      hours = Math.floor(mins / 60);
      mins = mins % 60;
    }

    var timeString = '';
    if (hours > 0) timeString += hours + ':';
    timeString += (hours > 0 && mins < 10 ? "0" : "") + mins + ':';
    timeString += (secs < 10 ? "0" : "") + secs;

    return timeString;
  }

  static stringify(obj:any):string {
    var text = "";
    try {
        text = JSON.stringify(obj);
      } catch (err) {
        console.log("log:err: " + err + ", obj: " + obj);
        text = "{ stringifyError: \""+err+"\" }";
      }
      return  text;
  }


  static getLogText(text:any, showMillies: boolean = false, type:string = "LOG"):string { // TOOD: showMillies er hardkodet til true
    return MiscUtil.getTimeString(new Date(), true) + ": " + type +": " + text;
  }

  static getCartParams(cart:any):any {
    var params:any = { };

    cart.items.forEach((pw:any, idx:number) => {
      MiscUtil.extend(params, MiscUtil.getCartItemParams(pw, idx));
    });

    return params;
  }

  static getCartItemParams(pw:any, idx?:Number, path?:string):any {
    var params:any = { };

    if (idx == undefined) idx = 0;
    if (path == undefined) path = "";

    var name = "item_" + idx;
    if (path != "") name += "_" + path;

    var ai:string = pw.additionalInfo || "-";
		if (ai.indexOf("_") != -1) ai = ai.replace(/_/g, " ");
    
    var gc = pw.guestCountsAsString || "0";

    params[name] = "" 
			+ pw.productId
			+ "_" + pw.startAsIso8601
			+ "_" + pw.endAsIso8601
			+ "_" + gc 
			+ "_" + pw.quantity 
			+ "_" + (pw.campaignCode || "-") 
			+ "_" + (pw.priceId || "-") 
			+ "_" + (ai)
			+ "_" + (pw.customAmountDouble || "-")
		;

    // console.log(MiscUtil.getTimeString() + ": " + "getCartItemParams, current: " + pw.cartItemParams[name]);
    // console.log(MiscUtil.getTimeString() + ": " + "getCartItemParams, new    : " + params[name]);
    
    var types = [ "accessories", "groupItems", "packageItems" ];

    // console.log("pw.accessories: "+  !!pw.accessories);
    // console.log("pw.accessories: ", pw.accessories);
    

    types.forEach((type:string, tIdx:number) => {
      if (!pw[type]) {
        // console.log("!pw{type], type: " + type);
        return true;
      }

      pw[type].forEach((child:any, cIdx:number) => {
        //if (child.quantity == 0.0) return true;
        // console.log("child: ", child);
        
        var childPath = path != "" ? path + "-" : "";
        childPath += type + "-" + cIdx;
        
        var childObj = this.getCartItemParams(child, idx, childPath);
        
        MiscUtil.extend(params, childObj);
      });
      return true;
    });

    return params;
  }
  
  static updateGuestCountsFromMap(gcs:any) {
    gcs.guestCountsAsString = "";
    gcs.count = 0;
    gcs.size = 0;
    
    let lastGc = 0;
    for (var i = 0; i < 5; i++) {
      var gc = gcs.map[i];
      if (gc) lastGc = i;
    }

    for (var i = 0; i < 5; i++) {
      var gc = gcs.map[i];
      if (gc || lastGc > i) {
        gcs.size++;
        gcs.count += gc;
        if (i != 0) gcs.guestCountsAsString += ",";
        gcs.guestCountsAsString += gc;
      }
    }
  
  }

  static hasOwnNestedProperty(obj:any, propName:string):boolean {
    if (obj === undefined || !propName) return false;
  
    var properties = propName.split('.');
    var current = obj;
  
    for (var i = 0; i < properties.length; i++) {
      var prop = properties[i];
  
      if (!current || !current.hasOwnProperty(prop)) {
        return false;
      } else {
        current = current[prop];
      }
    }
  
    return true;
  }

  static getOwnNestedProperty(obj:any, propName:string):any {
    if (obj === undefined || !propName) return false;
  
    var properties = propName.split('.');
    var current = obj;
  
    for (var i = 0; i < properties.length; i++) {
      var prop = properties[i];
  
      if (!current || !current.hasOwnProperty(prop)) {
        return undefined;
      } else {
        current = current[prop];
      }
    }
  
    return current;
  }

  static setOwnNestedProperty(obj:any, propName:string, value:any):any {
    // console.log("propName: " + propName + "; value: " + value + ", obj: ", obj);
    if (obj === undefined || !propName) return false;
  
    var properties = propName.split('.');
    let lastProp = properties[properties.length-1];
    var current = obj;
  
    for (var i = 0; i < properties.length-1; i++) {
      var prop = properties[i];
  
      if (!current || !current.hasOwnProperty(prop)) {
        // console.log("propName: " + propName + "; prop: " + prop + ", current: ", current);
        return undefined;
      } else {
        current = current[prop];
      }
    }

    // console.log("propName: " + propName + "; lastProp: " + lastProp + ", current: ", current);

    if (!current || !current.hasOwnProperty(lastProp)) {
      return undefined;
    } 

    let currentValue = current[lastProp];
    // console.log("found property, propName: " + propName + ", cv: " + currentValue + " -> " + value + ", obj: ", obj);
    
    // if (currentValue !== undefined && value === undefined) {
    //   delete current[lastProp];
    //   return undefined;
    // }
    
    if (currentValue === value) {
      return currentValue;
    }
    
    current[lastProp] = value;
    return currentValue;
  }


  // static getOwnDeepProperty(obj:any, propName:string):any {
  //   if (typeof obj === 'object' && obj !== null) { // only performs property checks on objects (taking care of the corner case for null as well)
  //     if (obj.hasOwnProperty(propName)) {              // if this object already contains the property, we are done
  //       return obj[propName];
  //     }
  //     for (var p in obj) {     
  //       let prop = obj[propName];
  //       if (typeof prop != 'object') continue;   

  //       if (obj.hasOwnProperty(p) &&      
  //           this.getOwnDeepProperty(obj[p], prop)) { 
  //         return true;
  //       }
  //     }
  //   }
  //   return false;                                  
  // }

  static getColor (idx:number, type:("value"|"bg"|"fg") = "value" ) {
    var color = "black";
    let colors = Object.values(Colors);
    if (type == "bg") colors = Object.values(ColorClassBg);
    if (type == "fg") colors = Object.values(ColorClassFg);
    
    if (idx >= 0 && idx < colors.length) {
      color = colors[idx];
    }
    return color;
  }


  static toId (s:string, spaceReplacement:string = "_") {
    if (!s) return "";
    
    if(!spaceReplacement) spaceReplacement = "_";
    
    s = s.toLowerCase();
    s = s.replace(/ /g, spaceReplacement);
    s = s.replace(/[øö]/g, "o");
    s = s.replace(/[åæä]/g, "a");
    s = s.replace(/&/g, "and");
    
    s = s.replace(/[^a-zA-Z0-9]/g, spaceReplacement);
    return s;
  }

  
  static listToMap(list:any[], key:string = "id"):any {
    let map:any = { };
    list.forEach(obj => {
      map[obj[key]] = obj;
    });
    return map;
  }

  static mapToList(map:any, sortOrder:any[] = []) {
    let list:any[] = [];

   

    if (sortOrder.length == 0) {
      // list = this.values(map);
      for (let key in map) {
        let obj = map[key];
        if (obj === undefined) continue;

        if (typeof obj !== "object" ) {
          
          obj = { "key": key, "value": obj};
        }
        list.push(obj);
      }
    }
    else {
      for (let key of sortOrder) {
        let obj = map[key];
        if (obj === undefined) continue;

        if (typeof obj !== "object" ) {
          obj = { "key": key, "value": obj};
        }
        list.push(obj);
      }
    }

    // if (exlude.length != 0) {
    //   let listFilter:any[] = [];
    //   for (let key of exlude) {
    //     let obj = map[key];
    //     if (obj === undefined) continue;
    //     list
    //   }
    // }
    

    return list;
  }


}