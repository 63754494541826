import { Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { BooktechAppService } from './booktech-app.service';


import { DATAID } from './data.service';
import { MiscUtil } from '../util/misc.util';
// import { FilterPipe } from 'ngx-filter-pipe';

export class AppAdminService {

  // private filter:FilterPipe;

  constructor(public injector:Injector, public bas: BooktechAppService ) {
    // Log.log('AppAdminService');

    // this.filter = injector.get(FilterPipe);

  }

  isBn():boolean {
    let al = this.bas.ds.login;
    return this.isSu() || al.success && al.user && al.user.isAdminSu && al.user.username == "bn";
  }
  isSu():boolean {
    let al = this.bas.ds.login;
    return al.success && al.user && al.user.isSu;
  }
  isAdmin():boolean {
    let al = this.bas.ds.login;
    return al.success && al.user && al.user.isAdminSu;
  }
  isProvider():boolean {
    let al = this.bas.ds.login;
    return al.success && al.user && al.user.isProvider;
  }
  isAgent():boolean {
    let al = this.bas.ds.login;
    return al.success && al.user && al.user.isAgent;
  }
  isSubuser():boolean {
    let al = this.bas.ds.login;
    return al.success && al.user && al.user.userRole.isSubuser;
  }
  isAdminProvider():boolean {
    return this.isAdmin() || this.isProvider();
  }

  isRoot() {
    return this.bas.ds.findCid() === "root";
  }
  isCb() {
    return this.bas.ds.findCid() === "cloudbooking";
  }
  isCbSu(checkSuParam:boolean = true) {
    // if (checkSuParam) {
    //   if (MiscUtil.getUrlVars()["superuser"] != "true") return false;
    // }
    return this.isCb() && this.isSu()
  }
 
  getProducts(options:any = { }):Promise<any> {
    var params:any = { 
      actionType: "appAdmin", 
      action: "getProducts",
      jil: options.jil || "min"
    };


    return this.bas.ds.getFromJson(DATAID.APP_ADMIN_PRODUCTS, params, true).then((json) => {
      if(this.bas.envtest) console.log("AppAdminService, getProducts.then: ", json);
      return json;
    });
  }

  getProduct(id:any, options:any = { }):Promise<any> {
    var params:any = { 
      actionType: "appAdmin", 
      action: "getProduct",
      id: id
    };

    if (options.jil) params.jil = options.jil;
    if (options.copy) params.copy = options.copy;

    return this.bas.ws.json(params).then((json) => {
      return json;
    });
  }
  
  saveProduct(id:any, product:any = { }):Promise<any> {
    var params:any = { 
      actionType: "appAdmin", 
      action: "saveProduct",
      id: id,
      product: JSON.stringify(product)
    };

    return this.bas.ws.json(params).then((json) => {
      return json;
    });
  }

  //

  getUser(id:any, options:any = { }):Promise<any> {
    var params:any = { 
      actionType: "appAdmin", 
      action: "getUser",
      id: id
    };

    if (options.jil) params.jil = options.jil;
    if (options.jil2) params.jil2 = options.jil2;
    if (options.params) MiscUtil.extend(params, options.params);

    return this.bas.ws.json(params).then((json) => {
      return json;
    });
  }

  saveUser(id:any, user:any = { }):Promise<any> {
    var params:any = { 
      actionType: "appAdmin", 
      action: "saveUser",
      id: id,
      user: JSON.stringify(user)
    };

    return this.bas.ws.json(params).then((json) => {
      return json;
    });
  }

  //

  getOrder(id:any, options:any = { }):Promise<any> {
    var params:any = { 
      actionType: "appAdmin", 
      action: "getOrder",
      id: id
    };

    if (options.jil) params.jil = options.jil;

    return this.bas.ws.json(params).then((json) => {
      return json;
    });
  }

  saveOrder(id:any, obj:any = { }):Promise<any> {
    var params:any = { 
      actionType: "appAdmin", 
      action: "saveOrder",
      id: id,
      order: JSON.stringify(obj)
    };

    return this.bas.ws.json(params).then((json) => {
      return json;
    });
  }
  
  
  // 
  getOrderStats(options:any = { }):Promise<any> {
    var params:any = { 
      actionType: "appAdmin", 
      action: "getOrderStats"
    };

    return this.bas.ds.getFromJson(DATAID.APP_ADMIN_ORDER_STATS, params, true).then((json) => {
      if(this.bas.envtest) console.log("AppAdminService, getOrderStats.then: ", json);
      return json;
    });
  }


}
