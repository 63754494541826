import { Injector } from '@angular/core';

import { BooktechAppService } from './booktech-app.service';

import { Observable, BehaviorSubject, Subject, from } from 'rxjs';
import { MiscUtil } from '../util/misc.util';
import { BtEvent } from './event.service';
import { DomSanitizer } from '@angular/platform-browser';

export const DATAID = {
  "DS_SAVE_TIME": "DS_SAVE_TIME",

  "APP_CONFIG": "APP_CONFIG",
  "APP_DEVICE": "APP_DEVICE",
  "APP_LOGIN": "APP_LOGIN",
  "APP_LANG": "APP_LANG",
  "APP_URL_PARAMS": "APP_URL_PARAMS",
  "APP_URL_QUERY_PARAMS": "APP_URL_QUERY_PARAMS",
  "APP_URL_PATHNAME": "APP_URL_PATHNAME",

  "APP_WEB_OPERATING_MESSAGES": "APP_WEB_OPERATING_MESSAGES",


  "APP_ADMIN_PRODUCTS": "APP_ADMIN_PRODUCTS",
  "APP_ADMIN_ORDER_STATS": "APP_ADMIN_ORDER_STATS", 
  
  "UI_MAIN_CONTENT": "UI_MAIN_CONTENT"
  
}

const INIT_FROM_STORAGE = [

  DATAID.APP_CONFIG,
  DATAID.APP_DEVICE,
  DATAID.APP_LOGIN,
  DATAID.APP_LANG,
];

const LOCAL_VARS:any = {
  "APP_CONFIG": "config",
  "APP_DEVICE": "device",
  "APP_LOGIN": "login",
  "APP_LANG": "lang"
};

export const CUSTOM_HOSTNAMES:({[index: string]:string}) = {
  "nesbyenbooking.booktech.net": "nesbyen",
  "test.nesbyenbooking.booktech.net": "nesbyen",
}


export class DataService {

  storage:Storage|undefined;
  sessionStorage:Storage|undefined;
  data: { [index: string]: BehaviorSubject<any> } = { };

  sanitizer:DomSanitizer;

  // 
  config:any = undefined;
  device:any = undefined;
  login:any = { success: false };;
  lang:any = { code: "no" };

  localVarInitMap:{[index: string]:boolean} = { };
  localVarInitValueMap:{[index: string]:boolean} = { };
  

  constructor(public injector:Injector, public bas: BooktechAppService ) {
    if(this.bas.envtest) console.log("DataService.constructor");

    this.storage = this.bas.nossr ? localStorage : undefined;
    this.sessionStorage = this.bas.nossr ? sessionStorage : undefined;

    this.sanitizer = injector.get(DomSanitizer);
   
    Object.keys(DATAID).forEach((did) => {
      this.get(did, { DS_INIT: true });
    });


    let that:any = this;

    let localVars = Object.keys(LOCAL_VARS);
    localVars.forEach((did) => {
      this.localVarInitMap[did] = false;

      this.get(did).subscribe((obj) => { 
        
        let varName = LOCAL_VARS[did];
        if(this.bas.envtest) console.log(MiscUtil.getLogText("DataService.LOCAL_VARS: "+did+", update: "), that[varName], " -> ", obj);

        if (did == DATAID.APP_LANG) {
          obj = obj.code ? obj : { code: "no" };
        } else if (did == DATAID.APP_CONFIG) {

          let ac = obj.appConfig;
          if (ac) {
            if (ac.messages) {
              ac.messagesTrust = { };
              // if(this.bas.envtest) console.log("this.bas: ", this.bas);
              for (var key in ac.messages) {
                ac.messagesTrust[key] =  this.getMessageTrust(ac.messages[key]);
              };
            }
          }
         

        }
        that[varName] = obj; 
      });
    });

    let ic = bas.initConfig;
    


    INIT_FROM_STORAGE.forEach((did) => {
      this.getFromStorage(did).then((res) => {
        if(this.bas.envtest) console.log(MiscUtil.getLogText('DataService.INIT_FROM_STORAGE: ' + did  + ' initialized, res: '), res);

        if (did == DATAID.APP_CONFIG) {

        }

        if (this.localVarInitMap[did] !== undefined) {
          this.localVarInitMap[did] = true;
          this.localVarInitValueMap[did] = res.DS_SAVE_TIME !== undefined;
          let allTrue = Object.values(this.localVarInitMap).every(Boolean);
          //console.log(MiscUtil.getLogText("DataService.INIT_FROM_STORAGE. ALL_TRUE: " + allTrue));

          if (allTrue) {
            if (bas.standalone) {
              if (ic.appConfig) this.config = ic.appConfig;
              // setTimeout(() => {
              //   this.bas.es.trigger(BtEvent.DS_INIT);
              // }, 1)

              // return;
            }
            this.bas.es.trigger(BtEvent.DS_INIT);
          }
        }
      });
    })



  }

  

  has(did:string) {
    return this.data[did] !== undefined;
  }

  get(did:string, initValue:any = { }): BehaviorSubject<any> {
    if (this.data[did] === undefined) {
        this.data[did] = new BehaviorSubject(initValue);
    }

    return this.data[did];
  }
  getValue(did:string, defaultValue:any = { }):any {
    let val = this.get(did).getValue();
    return val || defaultValue;
  }

  find(did:string, defaultValue:any = { }, session:boolean = false):BehaviorSubject<any> {
    if (this.data[did] === undefined) {
      this.getFromStorage(did, session);
      // return this.get(did);
    }
    return this.get(did, defaultValue);
  }

  set(did:string, data:any) {
    // console.log("DataService.set, did: " + did + ", data: ", data);
    this.get(did).next(data || { });
  }

  async save(did:string, data:any, session:boolean = false):Promise<any> {
    
    if (data !== undefined && Object.keys(data).length) {
      data[DATAID.DS_SAVE_TIME] = new Date().getTime();
     
    }

    //console.log("saveTime: "+ new Date(data[DATAID.DS_SAVE_TIME]) + ", keys: ", Object.keys(data));
    

    this.set(did, data);


    if(this.bas.envtest) console.log("Saving data, id: " + did + ", data: ", data);

    if (session) this.sessionStorage && this.sessionStorage.setItem(did, JSON.stringify(data));
    else this.storage && this.storage.setItem(did, JSON.stringify(data));
    return data;
  }

  getFromStorage(did:string, session:boolean = false):Promise<any> {
    return new Promise<any>((res, rej) => {
      let storage = session ? this.sessionStorage : this.storage;

      const dataString = storage && storage.getItem(did);
      if (dataString) {
        const json = JSON.parse(dataString);
        if(this.bas.envtest) console.log("DataService.getFromStorage.set: " + did);
        this.set(did, json);
        res(json);
        return;
      } 
      else if(this.bas.envtest) console.log("getFromStorage, dataString is undefined, did: " + did);
  
      res({ });
    });
   
  }

  async getFromJson(did:string, params:any, save:boolean = true):Promise<any> {
    return this.bas.ws.json(params).then((json) => {
      if(this.bas.envtest) console.log("getFromJson. json: ", json);
      if (save) this.save(did, json);
      else this.set(did, json);
      return json;
    });
  }

  findCidFromLocation() {
    // if (this.bas.ssr) return false;

    // let loc = // window.location;
    // console.log("findCid, loc: ", loc);

    let hn = this.bas.nossr ? window.location.hostname : "cloudbooking.io";
    let pn = this.bas.LOC.path();

    if (CUSTOM_HOSTNAMES[hn]) return CUSTOM_HOSTNAMES[hn];

    let cid = "";
    if (hn.endsWith("booktech.app") || hn.endsWith("cloudbooking.io")) {
      let parts = hn.split(".");
      if (parts.length >= 4 && parts[1] != "test") {
        cid = parts[0];
        console.log("cid: " + cid); 
      }
    }

    if (cid) return cid;

    if (this.bas.settings.appId == "cbsite" && pn.startsWith("/")) {
      let parts = pn.split("/");
      if (parts.length >= 3) cid = parts[2];
      console.log("cid: " + cid); 
    }
    else 
    if (pn.startsWith("/api/")) {
      let parts = pn.split("/");
      // console.log("pn.parts: ", parts);
      if (parts.length >= 3) cid = parts[2];
    } 
    // else console.log("pn: " + pn);
    // booktech.app

    /*
host: "192.168.1.82:8200"
hostname: "192.168.1.82"​
href: "https://192.168.1.82:8200/api/nesbyen/widget/test"

origin: "https://192.168.1.82:8200"
pathname: "/api/nesbyen/widget/test"
    */
    return cid;
  }

  findCid():string {
    // let cid = this.getValue(DATAID.APP_URL_PARAMS).cid;
    // if (cid) {
    //   console.log("findCid from APP_URL_PARAMS: " + cid);
    //   return cid;
    // }
    // cid = this.config?.cid ;
    // if (cid && cid == "cloudbooking") cid = "";
    // if (cid) {
    //   console.log("findCid from this.config?.cid: " + cid);
    //   return cid;
    // }

   
    // cid = this.findCidFromLocation();
    // if (cid) {
    //   console.log("findCid from findCidFromLocation: " + cid);
    //   return cid;
    // }

    // cid = this.bas.settings.cid ;
    // if (cid) {
    //   console.log("findCid from this.bas.settings.cid: " + cid);
    //   return cid;
    // }

    
    // cid = this.getValue(DATAID.APP_URL_QUERY_PARAMS).cid;
    // if (cid) {
    //   console.log("findCid from APP_URL_QUERY_PARAMS: " + cid);
    //   return cid;
    // }

    // cid = "_";
    // console.log("findCid from FALLBACK: " + cid);
    // return cid;

    let cCid =  this.config?.cid;
    if (cCid && cCid == "cloudbooking") cCid = "";

    let cid = this.getValue(DATAID.APP_URL_PARAMS).cid
      || cCid 
      || this.findCidFromLocation()
      || this.bas.settings.cid
      || this.getValue(DATAID.APP_URL_QUERY_PARAMS).cid;

    return cid || "_";
  }

  findLangFromLocation() {
    // if (this.bas.ssr) return false;

    // let loc = // window.location;
    // console.log("findCid, loc: ", loc);

    let hn = this.bas.nossr ? window.location.hostname : "nossr.no";
    let pn = this.bas.LOC.path();

    let lang = "";

    if (this.bas.settings.appId == "cbsite" && pn.startsWith("/")) {
      let parts = pn.split("/");
      if (parts.length >= 2) lang = parts[1];
      console.log("lang: " + lang); 
    }
    // else 
    // if (pn.startsWith("/api/")) {
    //   let parts = pn.split("/");
    //   // console.log("pn.parts: ", parts);
    //   if (parts.length >= 3) cid = parts[2];
    // } 
    // else console.log("pn: " + pn);
    // booktech.app

    /*
host: "192.168.1.82:8200"
hostname: "192.168.1.82"​
href: "https://192.168.1.82:8200/api/nesbyen/widget/test"

origin: "https://192.168.1.82:8200"
pathname: "/api/nesbyen/widget/test"
    */
    return lang;
  }

  findLang():string {
 

    // let cCid =  this.config?.cid;
    // if (cCid && cCid == "cloudbooking") cCid = "";

    let lang = this.getValue(DATAID.APP_URL_PARAMS).lang
      // || cCid 
      || this.findLangFromLocation()
      || this.getValue(DATAID.APP_URL_QUERY_PARAMS).lang
      || this.bas.settings.langs[0];

    let langs:string[] = this.bas.settings.langs;
    if (langs.indexOf(lang) < 0) lang = langs[0];

    return lang || "no";
  }



  getMessageTrust(message:any):any {
    var mt = MiscUtil.clone(message);
    var texts = mt.texts || mt;
    for (let lang in texts) {
      let textValue = texts[lang];
      let isTrust = !!textValue.changingThisBreaksApplicationSecurity;
      // Log.l("getMessageTrust, typeof textValue: " + (typeof textValue) + ", hasProp: " + isTrust);
      
      texts[lang] = isTrust ? textValue : this.sanitizer.bypassSecurityTrustHtml(textValue);  //  + "Trust"
    }
    return mt;
  }

}
