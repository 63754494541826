
export const lowerCase = "abcdefghijklmnopqrstuvwxyz";
export const upperCase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
export const numeric 	= "0123456789";
export const alphabet  = lowerCase + upperCase + numeric;


export const Colors = {
  "blue": 			"#0166B4",
  "green": 			"#00A560",
  "red": 				"#ED1C24",
  "purple": 			"#5C2E91",
  "orange": 			"#F68121",
  
  "purple-blue": 		"#21409B",
  "yellow-orange": 	"#F9A61C",
  "orange-red": 		"#EE3E3E",
  "blue-green": 		"#01AAAD",
  "red-purple": 		"#A3248F",
  "green-yellow": 	"#70BE44",
  "yellow": 			"#FFDE17",
  
  "gray-10": 			"hsl(0, 0%, 10%)",
  "gray-20": 			"hsl(0, 0%, 20%)",
  "gray-30": 			"hsl(0, 0%, 30%)",
  "gray-40": 			"hsl(0, 0%, 40%)",
  "gray-50": 			"hsl(0, 0%, 50%)",
  "gray-60": 			"hsl(0, 0%, 60%)",
  "gray-70": 			"hsl(0, 0%, 70%)",
  "gray-80": 			"hsl(0, 0%, 80%)",
  "gray-90": 			"hsl(0, 0%, 90%)",
  "white": 			"white",
  "gray": 			"gray",
  "black": 			"black"
};

export const ColorClassBg = [
  "bgc-blue", 
  "bgc-green",	
  "bgc-red",
  "bgc-purple",
  "bgc-orange",	
  
  "bgc-purple-blue", 
  "bgc-yellow-orange",	
  "bgc-orange-red",
  "bgc-blue-green",
  "bgc-red-purple",	
  "bgc-green-yellow",
  "bgc-yellow"
];

export const ColorClassFg = [
  "fgc-blue", 
  "fgc-green",	
  "fgc-red",
  "fgc-purple",
  "fgc-orange",	
  
  "fgc-purple-blue", 
  "fgc-yellow-orange",	
  "fgc-orange-red",
  "fgc-blue-green",
  "fgc-red-purple",	
  "fgc-green-yellow",
  "fgc-yellow"
];

