import {  AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { NzRadioButtonStyle } from 'ng-zorro-antd/radio';
import { NzSelectModeType } from 'ng-zorro-antd/select';
import { AppService } from 'src/app/services/app.service';
import { BooktechAppService } from '../services/booktech-app.service';

export class DynformControl {
  // apps:AppService;
  value: any;
  key: string;
  valuePath: string;
  // paramName: string;
  label: string;
  placeholder: string;
  mk: string;
  mkInfo: string;
  required: boolean;
  disabled: boolean;
  validators:ValidatorFn[];
  order: number;
  viewLevel: number = 1;
  controlType: string;
  type: string;
  options: (() => any[]); // ({value: string, label: string}[])) ;
  // optionsExclude: (() => any[]) = () => [];
  onChange: ((event:any) => void);
  show: ((control:DynformControl) => boolean);
  updateSourceOnChange:boolean = false;
  optionsAllowEmpty:boolean;
  optionsEmptyLabel:string = "------------";
  optionsFieldValue: string = "value";
  optionsFieldLabel: string = "label";
  
  source:any;
  data:any;
  colSize: string;
  grid:any;
  radioButtonStyle?: NzRadioButtonStyle;
  selectMode?:NzSelectModeType = "default";
  fc:UntypedFormControl|UntypedFormGroup|null = null;
  fg:UntypedFormGroup|null = null;
  children:DynformControl[] = [];
  childMap:DynformControlsMap = { };

  constructor(options: {
      // apps?:AppService,
      value?: any;
      key?: string;
      valuePath?: string;
      // paramName?: string;
      label?: string;
      placeholder?: string;
      mk?: string;
      mkInfo?: string;
      required?: boolean;
      disabled?: boolean;
      validators?:ValidatorFn[];
      order?: number;
      viewLevel?:number;
      controlType?: string;
      type?: string;
      options?:  (() => any[]); // ({value: string, label: string}[]));
      // optionsExclude?: (() => any[]);
      onChange?: ((event:any) => void);
      show?: ((control:DynformControl) => boolean);
      updateSourceOnChange?:boolean;
      optionsAllowEmpty?:boolean;
      optionsEmptyLabel?:string;
      optionsFieldValue?: string;
      optionsFieldLabel?: string;
      source?: any;
      data?: any;
      
      colSize?: string;
      grid?:any;
      radioButtonStyle? : NzRadioButtonStyle;
      selectMode?: NzSelectModeType;
      children?:DynformControl[];
    } = {}) {
    // this.apps = options.apps as AppService;
    this.key = options.key || '';
    this.valuePath = options.valuePath || options.key || '';
    // this.paramName = options.paramName || '';
    this.label = options.label || '';
    this.placeholder = options.placeholder || '';
    this.mk = options.mk || '';
    this.mkInfo = options.mkInfo || '';
    this.required = !!options.required;
    this.disabled = !!options.disabled;
    this.validators = options.validators || [];
    this.order = options.order === undefined ? 1 : options.order;
    this.viewLevel = options.viewLevel === undefined ? 1 : options.viewLevel;
    this.controlType = options.controlType || '';
    this.type = options.type || '';
    this.options = options.options || (() => { return [] });
    // this.optionsExclude = options.optionsExclude || (() => { return [] });
    this.onChange = options.onChange || ((event:any) => { });

    if (options.show !== undefined) this.show = options.show ;
    else this.show = ((control:DynformControl) => { return true });

    if (options.updateSourceOnChange !== undefined) this.updateSourceOnChange = !!options.updateSourceOnChange;
    this.optionsAllowEmpty = !!options.optionsAllowEmpty;
    if (options.optionsEmptyLabel) this.optionsEmptyLabel = options.optionsEmptyLabel;
    if (options.optionsFieldValue) this.optionsFieldValue = options.optionsFieldValue;
    if (options.optionsFieldLabel) this.optionsFieldLabel = options.optionsFieldLabel;

    this.source = options.source || undefined;
    this.data = options.data || { };

    this.colSize = options.colSize || "12";
    if (options.grid) this.grid = options.grid;
    
    if (options.radioButtonStyle) this.radioButtonStyle = options.radioButtonStyle;
    if (options.selectMode) this.selectMode = options.selectMode;
    this.children = options.children || [];

    this.value = options.value;
  }




  // public get value() {
  //   return this._value;
  // }


  // formGroup() {
  //   return this.fg as FormGroup;
  // }



}

export class DynformControlsMap {
  [index: string]: DynformControl
}



