import { Injectable, Injector } from '@angular/core';

import { BooktechAppService } from '../lib/core/services/booktech-app.service';
import { MiscUtil } from '../lib/core/util/misc.util';

import { environment } from 'src/environments/environment';
import { APP_CONFIG_INIT } from 'src/environments/config.init';

export const APP_DATAID = {

}

@Injectable({
  providedIn: 'root'
})
export class AppService {
  
  bas:BooktechAppService;

  constructor( public injector:Injector) { 
    this.bas = new BooktechAppService(injector, {
      apps: this,
      environment: environment,
      appConfig: APP_CONFIG_INIT,
      data: {

       } 
    });
    
    if(this.bas.envtest) console.log(MiscUtil.getLogText("AppService.constructor"));


  }
}
